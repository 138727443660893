.ModalUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 200;
  transition: opacity 0.2s ease-out;
  opacity: 0;

  &.entering {
    display: flex;
  }

  &.entered {
    display: flex;
    opacity: 1;
  }

  &.exiting {
    display: none;
    visibility: 0;
  }

  &.exited {
    display: none;
    visibility: 0;
  }

  .Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $BasicColor1;
    border-radius: 3px;
    min-width: 320px;
    max-height: calc(100vh - 120px);

    .Inner {
      position: relative;
      width: 450px;

      .Top {
        display: flex;
        align-items: center;
        padding: 10px 30px;
        padding-right: 0px;
        border-bottom: 1px solid $BasicColor7;
        box-sizing: border-box;
        height: 60px;

        .Title {
          margin: 0;
        }

        .CloseButton {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }

    .Children {
      //padding: 30px 30px 10px 30px;
      //overflow-y: auto;
      max-height: calc(100vh - 260px);
    }

    .Bottom {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      background-color: $BasicColor11;
      padding: 10px 20px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      .Btn {
        margin-right: 10px;
        min-width: 130px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    padding: 0;
  }


  .PauseSubscription {
    padding: 30px 30px 10px 30px;
  }

  .Delete {
    padding: 30px 30px 10px 30px;

    h3 {
      line-height: 1.7em;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 300;
      margin-bottom: 20px;
      padding: 0;
    }

    .InformationList {
      list-style-type: none;
      padding: 0px;
      font-size: 1.3rem;

      li {
        line-height: 1.5em;
      }
    }

    .WarningText {
      display: flex;
      align-items: center;

      img {
        height: 25px;
        width: 25px;
      }

      p {
        color: $BasicColor9;
        font-weight: 700;
        padding-left: 5px;
      }
    }
  }
}

.ModalUnderlay .Modal .Inner .Top .CloseButton img {width:60px;}
