.Dropzone {
  display: flex;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  border: 1px dashed $MainColor3;
  background-color: $BasicColor1;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  p {
    transition: all 0.2s ease-in-out;
  }
  &:hover:not(.Disabled),
  &.DragActive:not(.Disabled) {
    border-color: $MainColor1;
    p:not(.Bold) {
      color: $MainColor1;
    }
  }
}
.FileDetails {
  display: block;
  color: $MainColor3;
  margin-top: 0.2rem;
}
.Dropzone.Disabled {
  cursor: default;
  border-style: solid;
}
.Dropzone img {
  max-width: 100%;
  max-height: 100%;
}
.Dropzone img.Icon {
  width: 50px;
  margin-bottom: 10px;
}
.Dropzone p {
  margin: 0 0 5px 0 !important;
}
.Dropzone p.Bold {
  font-weight: 600;
}
.Dropzone .WarningMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  color: $BasicColor5;
}
.Dropzone img.WarningIcon {
  width: 30px;
}

.ImageInfo {
  font-size: 1rem;
  margin-top: 10px;
  color: $BasicColor2;
}
.MultipleDropzone {
  justify-content: flex-start;
  padding: 10px;
  overflow: auto;
  max-width: 400px;
}
.MultipleDropzoneTop {
  flex: 0 0 132px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.Dropzone.MultipleDropzone p.MultipleDropzoneUploadedFilesTitle {
  font-weight: 600;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  color: #18c60b;
}
