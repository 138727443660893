.FieldReportsSectionPage {
  &__title {
    text-align: left;
  }
  &__Filters {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }
    margin-bottom: 30px;
    &.Disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  &__Fields {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  &__NoResults {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
  }
  &__button {
    margin-bottom: auto;
    width: 100%;
    max-width: 270px;
    @media (max-width: 768px) {
      max-width: initial;
    }
  }
  &__buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
  &__FarmSelectWrapper {
    flex: 1 1;
    max-width: 300px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .Btn {
      justify-self: flex-start;
    }
    @media (max-width: 768px) {
      width: 100%;
      max-width: initial;
    }
  }
}

.FieldReportsSectionCard {
  border-radius: 5px;
  padding-top: 10px;
  max-width: 300px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
  flex: 0 0 300px;
  min-width: 250px;

  @media (max-width: 1200px) {
    max-width: initial;
  }
  @media (max-width: 768px) {
    flex: 1;
  }
  &__thumbnail {
    height: 176px;
    width: 100%;
    cursor: pointer;
    object-fit: contain;
  }

  &__closeReport {
    position: absolute;
    top: -18px;
    right: -20px;
    cursor: pointer;
    z-index: 20;
    width: 40px;
  }
  &__content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 460px;
  }
  &__header {
    color: #444444;
    font-size: 2.6rem;
    font-family: "Roboto";
    text-transform: uppercase;
    letter-spacing: -1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__subHeader {
    font-family: "Roboto";
    font-size: 1.8rem;
    color: #999;
  }
  &__item {
    color: #999;
    letter-spacing: 1px;
    font-size: 1.4rem;
    margin-bottom: 5px;

    &:nth-child(4) {
      margin-top: 10px;
    }
    .Good {
      color: $MainColor1;
      font-weight: 600;
    }
    .Bad {
      color: #ff0000;
      font-weight: 600;
    }
  }
  &__button {
    flex: 1;
    height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-whiteGreen {
      background-color: $BasicColor1;
      color: $MainColor1;
    }
    &-grey {
      border: 1px solid #d5d5d5;
      background-color: $BasicColor1;
      color: #999;
    }
  }
  &__buttonsContainer {
    &:nth-last-child(2) {
      margin-top: auto;
      margin-bottom: 10px;
    }
    display: flex;
    gap: 10px;
  }
}

.FieldReportsFilterSort {
  max-width: 300px;
  min-width: 150px;
  .FieldReportsFilter__sortImage {
    background-image: url("../assets/images/Icon_Arrow_Down.png");
    animation: fadeIcons 0.7s forwards;
    background-repeat: no-repeat;
    background-size: contain;
    width: 10px;
    height: 10px;
  }

  .FieldReportsFilter__content {
    padding: 0;
  }
  .FieldReportsFilter__header {
    justify-content: space-between;
  }
}

.FieldReportsFilterSort {
  .FieldReportsFilter.Selected {
    .FieldReportsFilter__sortImage {
      background-image: url("../assets/images/Icon_Arrow_Up.png");
    }
  }
  @media only screen and (max-width: 1200px) {
    max-width: initial;
    flex: 1;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}

.FieldReportsSortItem {
  height: 40px;
  background-color: rgba(0, 0, 0, 0.045);
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  cursor: pointer;
  font-family: "Roboto";
  font-size: 1.2rem;
  font-weight: 300;
  &:hover {
    background-color: $MainColor1;
    color: white;
  }
  &:first-of-type {
    border-top: 1px solid lightgrey;
  }
  &.Selected {
    background-color: $MainColor1;
    color: white;
  }
}

.FieldReportsFilterOuter {
  max-width: 300px;
  min-width: 300px;
  @media only screen and (max-width: 1200px) {
    max-width: initial;
    flex: 1;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
}

.FieldReportsFilter {
  font-family: $Font1;

  &__header {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 15px;
    min-height: fit-content;
    min-height: -moz-fit-content;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.045);
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    font-size: 1.4rem;
    transition: background-color 0.7s ease-out;
  }

  &__headerImage {
    background-image: url("../assets/images/Icon_Plus.png");
    background-size: contain;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
    animation: fadeIcons 0.7s forwards;
  }

  &__content {
    display: block;
    row-gap: 10px;
    column-gap: 20px;
    position: relative;
    z-index: 10;
    overflow: hidden;
    height: fit-content;
    padding: 10px 15px;
    transition: max-height 0.7s ease-out;
    max-height: 0;
  }
  &__expandableDrawer {
    max-height: 0;
    transition: max-height 0.7s ease-out;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
    overflow: hidden;
  }
  &__subFilter {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__subFilterTitle {
    color: #999999;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 300;
    font-family: "Roboto";
  }
  &__subFilterItems {
    color: #808080;
    font-size: 1.4rem;
    letter-spacing: 1px;
    text-align: center;
    padding: 0 10px 10px 10px;
  }
  &__subFilterItem {
    cursor: pointer;
    &.Selected {
      color: $MainColor1;
    }
  }
}

.FieldReportsFilter.Selected {
  .FieldReportsFilter__header {
    background-color: $MainColor1;
    color: $BasicColor1;
  }

  .FieldReportsFilter__headerImage {
    background-image: url("../assets/images/Icon_Minus.png");
    animation: fadeIcons 0.7s forwards;
    background-repeat: no-repeat;
  }

  .FieldReportsFilter__content {
    max-height: 500px;
    overflow: auto;
  }

  .FieldReportsFilter__expandableDrawer {
    max-height: 500px;
  }
}

.FieldReportsFilter.DATE {
  .FieldReportsFilter__content {
    & > div {
      height: 100%;
      .react-datepicker {
        height: 100%;
        width: 100%;
        position: relative;
      }
      .react-datepicker__header {
        height: 60px;
        background-color: initial;
        border-bottom: none;
      }
      .react-datepicker__navigation--previous {
        left: initial;
        right: 70px;
      }
      .react-datepicker__navigation--next {
        left: initial;
        right: 20px;
      }
      .react-datepicker__navigation {
        top: 15px;
      }
      .react-datepicker__current-month {
        font-size: 1.6rem;
        font-family: "Roboto";
        margin-bottom: 10px;
        text-align: start;
        padding: 0 25px;
      }
      .react-datepicker__month-container {
        height: 100%;
        width: 100%;
      }
      .react-datepicker__day-names {
        font-size: 1.4rem;

        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
      }
      .react-datepicker__month {
        height: 100%;
        display: flex;
        flex-direction: column;
        font-size: 1.4rem;
        font-family: "Roboto";
        gap: 10px;
        padding-bottom: 20px;
      }
      .react-datepicker__week {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
      }
      .react-datepicker__day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
      }
      .react-datepicker__day--selected {
        background-color: #7bbbdc;
        border-radius: 50%;

        color: #206694;
        font-weight: 700;
      }
    }
  }
}

.FieldReportsFilterItem {
  display: inline-block;
  margin: 0 10px 10px 10px;
  padding: 10px 15px;
  width: fit-content;
  width: moz-fit-content;
  height: fit-content;
  font-weight: 600;

  border: 2px solid #666666;
  border-radius: 25px;
  color: #666666;
  font-size: 1.2rem;
  letter-spacing: 1px;
  cursor: pointer;

  &.Selected {
    background-color: $MainColor1;
    color: $BasicColor1;
    border: 2px solid $MainColor1;
  }
  &:hover {
    background-color: #71e61f;
    color: $BasicColor1;
    border: 2px solid #71e61f;
  }
}

@keyframes fadeIcons {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.FieldReportPopUp {
  position: fixed;
  width: 88vw;
  height: 80vh;
  top: 10%;
  z-index: 10;
  left: 6vw;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.AddReportNotes {
  padding: 20px;
  height: calc(100vh - 120px);
}
