.RightPopOutUnderlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 300;
  transition: opacity 200ms ease;
  opacity: 0;

  &.entering {
    display: flex;
  }

  &.entered {
    display: flex;
    opacity: 1;
  }

  &.exited {
    display: none;
    visibility: unset;
  }
  &.Iframe {
    opacity: initial;
    z-index: initial;
  }
}

.RightPopOut {
  position: fixed;
  top: 0;
  right: 0;
  background: $BasicColor1;
  min-width: 320px;
  height: 100%;
  z-index: 300;
  transition: right 200ms ease;

  &.entering {
    right: -600px;
  }

  &.entered {
    right: 0px;
  }

  &.exiting {
    right: -600px;
  }

  &.exited {
    right: -1000px;
  }

  .Inner {
    position: relative;
    width: 560px;
    height: 100%;
    overflow-y: scroll;

    .Top {
      display: flex;
      align-items: center;
      padding: 10px 30px;
      border-bottom: 1px solid $BasicColor7;
      box-sizing: border-box;
      height: 60px;
      padding-right: 0px;

      .Title {
        margin: 0;
      }

      .CloseButton {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .Children {
      //padding: 30px 30px 10px 30px;
      //min-height: calc(100% - 60px)
    }

    .Bottom {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
      background-color: $BasicColor11;
      margin-top: auto;

      .Btn {
        height: 40px;
        padding: 5px 30px;
        font-size: 1.4rem;
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0px;
        }
      }
    }
  }

  h1,
  h2,
  h4 {
    margin: 0;
    padding: 0;
  }

  h3 {
    line-height: 1.7em;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 20px;
    padding: 0;
  }

  .PaymentCardAdd,
  .UsersUpdateDetails,
  .UsersAdd,
  .PaymentCardView,
  .TransactionHistoryView {
    padding: 30px 30px 10px 30px;
    min-height: calc(100vh - 120px);
  }

  .SubscriptionUpgrade ~ .Bottom .PanelErrorsContainer {
    display: flex;
    flex-direction: column;
    span {
      color: $BasicColor5;
    }
  }

  .SubscriptionUpgrade {
    padding: 30px 30px 10px 30px;
    min-height: calc(100vh - 120px);

    .HeaderDetails {
      display: flex;
      justify-content: space-between;

      .UpgradeHeader {
        width: 60%;
      }

      .PriceMonth {
        font-size: 2rem;
        color: $MainColor1;
        text-align: end;

        span {
          font-size: 1.3rem;
          color: $BasicColor2;
        }
        .PromoCodeValue {
          font-size: 1rem;
          color: $BasicColor2;
          margin: 5px 0;

          span {
            color: $MainColor1;
          }
        }
      }
    }

    .ContentDetails {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .CardList {
        display: flex;
        flex-direction: column;
        width: 100%;

        .CustomInput {
          height: auto;
          margin-bottom: 10px;

          .CVV {
            margin-left: auto;
          }
        }

        .CardDetails {
          display: flex;
          flex-direction: column;
          color: $MainColor3;
          font-size: 1.1rem;

          .CardName {
            font-size: 1.3rem;
            color: $BasicColor2;
            line-height: 1.9em;
          }
        }
      }

      .CVV {
        input {
          background-color: $BasicColor7;
          border: 1px solid $BasicColor6;
          color: $MainColor3;
          font-size: 1.3rem;
          height: 40px;
          width: 100px;
          border-radius: 3px;
          padding-left: 15px;
        }
      }
    }

    .InputContainerRow {
      display: block;
    }

    .SubText {
      text-align: right;
      margin-bottom: 20px;
      font-size: 1.1rem;
    }
  }

  .UsersUpdatePermissions {
    padding: 30px 30px 10px 30px;
    min-height: calc(100vh - 120px);

    .Checkbox {
      margin-bottom: 20px;
    }

    .SecondLayer {
      margin-left: 30px;
    }

    .ThirdLayer {
      margin-left: 60px;
    }

    .FourthLayer {
      margin-left: 90px;
    }
  }

  .UsersAdd,
  .UsersUpdateDetails,
  .PaymentCardAdd {
    min-height: calc(100vh - 60px);
    padding: 0px;
    display: flex;
    flex-direction: column;

    .Checkbox {
      margin-bottom: 20px;
    }

    .SecondLayer {
      margin-left: 30px;
    }

    .ThirdLayer {
      margin-left: 60px;
    }

    .FourthLayer {
      margin-left: 90px;
    }
  }

  .PaymentCardFilter,
  .TransactionHistoryFilter {
    padding: 30px 30px 10px 30px;
    min-height: calc(100vh - 120px);

    .FormSideBySide {
      margin-bottom: 20px;

      .CustomSelect {
        margin-right: 20px;
        width: 150px;
      }
    }
  }
}

.RightPopOut .Inner .Top .CloseButton img {
  width: 60px;
}
