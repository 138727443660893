.NavBarContainer {
  background-color: $MainColor2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 240px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  color: $BasicColor8;

  .EnterpriseUserTag {
    color: $BasicColor1;
    text-transform: uppercase;
    font-size: 1em;
    margin: 0px;
    letter-spacing: 7px;
  }

  &.LoggedOut {
    display: none;
  }

  &.RightOpen {
    height: 100vh;
    overflow: hidden;
  }

  .NavBarLogoImg {
    width: 180px;
    margin-top: 30px;
    height: 62px; // IE fix
    object-fit: contain;
  }

  .NavBarContentContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100% !important;
    margin-top: 20px;

    .LogOutMobileNav {
      display: none;
    }

    .NavBarMenu {
      flex: 1 0 0%;
      display: flex;
      flex-direction: column;
      min-height: 436px; // fix for safari flex bug
      p {
        font-size: 1rem;
        letter-spacing: 2px;
        color: $MainColor5;
        text-transform: uppercase;
        padding: 0px 30px;
        margin: 20px 0px 10px 0px;
        font-weight: 300;
      }

      .NavBarMenuItem {
        margin-left: 0px;

        .NavLinkTextLarge {
          font-size: 1.6rem;
        }

        .LogOutMobileNavText {
          display: none;
        }

        a {
          display: flex;
          align-items: center;
          color: $BasicColor8;
          text-decoration: none;
          width: 100%;
          padding: 12.5px 30px;

          .NavBarIcon {
            height: 25px;
            width: 25px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .NavBarBottom {
    padding: 30px;

    .BtnNav {
    }
  }
}

.NavBarContainer .NavBarContentContainer .NavBarMenu .NavBarMenuItem a:hover {
  background: $BasicColor2;
}

.NavBarContentContainerSmall {
  display: none;
}

.MenuBig .NavBarMenuItem a {
  font-size: 1.6rem;
}

.NavBarTrigger {
  display: none;
  height: 60px;
  width: 60px;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 199;

  .NavLogoContainer {
    cursor: auto;
  }
}

.BtnNavMenu {
  display: block;
}

.BtnNavCross {
  display: none;
}

.Active {
  color: $BasicColor1 !important;
}
