.Notifications {
  height: 100%;
  h1 {
    text-align: left;
  }

  .SettingsHeaders {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid $BasicColor7;

    p {
      font-size: 1.3rem;
      letter-spacing: 4px;
      width: 65px;
      text-align: center;
      margin-left: 40px;
      font-weight: 300;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      display: flex;
      border-bottom: 1px solid $BasicColor7;
      align-items: center;
      padding: 20px 0px;
      font-size: 1.3rem;

      .NotificationSettingsContainer {
        display: flex;
        align-items: center;
        margin-left: auto;

        .Push, .Email {
          width: 65px;
          display: flex;
          justify-content: center;
          margin-left: 40px;

          .Checkbox {
            margin-bottom: 0;

            .CustomInput {
              padding-left: 18px;
            }
          }
        }
      }
    }
  }
}

.NotificationsSpin {
  transform: translate(120px, -60px);
}