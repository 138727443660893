.RegisterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100vw - 380px);

  .RegistrationSummary {
    padding: 50px;
    padding-left: 25px;
    width: 33%;
    display: flex;
    height: fit-content;
    justify-content: flex-end;
    flex-direction: column;
    .CMSTextField {
      width: 100%;
      height: fit-content;
      border: 1px solid $BasicColor6;
      padding: 20px;
      margin-top: 25px;
      border-radius: 4px;
      max-width: 500px;
      .CMSContent {
        min-height: fit-content;
      }
    }
    .Summary {
      background-color: #f1f1f1;
      border-radius: 4px;
      margin: 15px 0;
      width: 100%;
      padding: 40px 30px;
      max-width: 500px;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .SubscriptionSummary {
        .PromoCodeValue {
          overflow: visible;
        }
      }

      .FlexContainerNoMargin {
        align-items: center;
        padding: 10px;
      }
      .Details {
        padding: 10px;
        padding-top: 0;
      }

      h3 {
        display: flex;
        justify-content: center;
        font-size: 18px;
      }

      h4 {
        color: $BasicColor2;
        padding-bottom: 0;
        font-size: 14px;
      }
    }
  }
  .RegistrationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 66%;
    padding: 50px;
    padding-right: 25px;
    max-width: 800px;
    // min-width: 500px;

    .RegistrationStep {
      border: 1px solid $BasicColor6;
      border-radius: 4px;
      width: 100%;
      padding: 40px 30px;
      margin: 15px 0;

      .RegistrationStepHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .Closed {
        padding-bottom: 0;
        display: flex;
        align-items: center;

        .Tick {
          margin-right: 5px;
        }
      }
      .PersonalInfoForm {
        width: 100%;
      }

      .PaymentCardForm {
        width: 100%;
      }

      .Register {
        width: 100%;
        padding: 0;

        .FormBox .ErrorMessage {
          margin-left: 0;
        }
        .PaymentCardForm {
          width: 100%;
        }

        .RegistrationOptions {
          flex-direction: column;

          .SubscriptionOption {
            width: 100%;
            min-height: fit-content;
            margin-bottom: 25px;

            .Holder {
              width: 100%;
              display: flex;
              padding: 30px;

              .Top {
                padding-top: 8px;
              }

              .Middle {
                width: 100%;
                margin-left: 30px;
                text-align: left;

                .PriceMonth {
                  flex-direction: row;
                  align-items: flex-end;

                  &.PriceWrap {
                    flex-wrap: wrap;
                  }
                }
              }

              .Continue {
                width: 0;
              }
            }
          }
        }

        .Continue {
          margin: 5px 0 0 0;
          width: 100%;

          .PromoInput {
            justify-content: flex-start;
          }
        }
      }

      .FlexContainerSpace {
        display: none;
      }

      .MultiInput {
        width: 50%;
      }
    }
  }
}

.RegisterSection
  .RegistrationWrapper
  .RegistrationStep
  .Register
  .RegistrationOptions
  .SubscriptionOption
  .Holder.CMSGeneratedSubscriptionTier {
  cursor: default;
  .Continue {
    width: initial;
  }
}

.Register {
  padding: 40px;
  max-width: 100vw;

  .StepCount {
    padding-left: 10px;
  }

  ul {
    padding: 0px;

    li {
      display: block;
    }
  }

  .RegistrationOptions {
    margin: 0px;
    padding: 0px;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;

    .Highlighted {
      .Holder {
        border: 1px solid $MainColor1;
        background-color: $BasicColor11;

        .Top .CheckImg {
          border: 1px solid $MainColor1;
          background: $MainColor4;

          .Circle {
            display: block;
            background: $MainColor1;
          }
        }
      }
    }

    .Holder {
      display: block;
      border: 1px solid $BasicColor6;
      border-radius: 3px;
      text-decoration: none;
      height: 100%;
      width: 300px;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      margin-right: 20px;

      .Top {
        display: block;
        text-align: center;
        padding: 30px 0px 0px 0px;

        .CheckImg {
          display: inline-block;
          width: 25px;
          height: 25px;
          box-sizing: border-box;
          border-radius: 50%;
          border: 1px solid $BasicColor6;
          background: $BasicColor7;
          position: relative;

          .Circle {
            display: none;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: $BasicColor7;
            position: absolute;
            top: 4px;
            left: 4px;
          }
        }
      }

      .Middle {
        display: block;

        .Icon {
          text-align: center;
          display: block;
          margin-top: 10px;
          margin-bottom: 10px;

          img {
            width: 60px;
            display: inline-block;
          }
        }
      }

      .Bottom {
        display: block;
        padding: 0px 30px 30px 30px;

        .Title {
          color: $BasicColor2;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          text-align: center;
          position: relative;

          .BoldTagline {
            font-size: 1.3rem;
            font-weight: 700;
            margin: 0px;
            line-height: 2.3em;
          }

          h3 {
            font-size: 1.5rem;
            font-weight: 500;
            padding-bottom: 5px;
          }

          h4 {
            font-size: 2rem;
            font-weight: 400;
            color: $BasicColor8;
            padding-bottom: 0px;
          }
        }
      }
    }

    .SubscriptionOption {
      min-height: 475px;
      width: 300px;

      &:last-of-type {
        margin-right: 0px;
      }

      .Holder {
        padding: 30px;
        padding-top: 10px;

        .Top {
          .CheckImg {
            margin-bottom: 20px;
          }
        }

        .Middle {
          text-align: center;

          .DescriptionContainer {
            display: inline-block;
          }
          .PriceMonth {
            display: flex;
            flex-direction: column;
          }

          .Price {
            font-size: 3.5rem;
            color: $MainColor1;
            font-weight: 100;
            padding: 0px;
            line-height: 1em;
          }

          .Month {
            margin: 0px;
          }

          .InfoText {
            margin-top: 20px;
            text-align: left;
          }

          .InfoList,
          .InfoList2 {
            text-align: left;

            li {
              font-size: 1.6rem;
              line-height: 1.6em;
              font-weight: 500;
              color: #333;
              display: block;
              background-image: url(../assets/images/Icon_Asset.png);
              background-position: 0;
              background-size: 14px;
              background-repeat: no-repeat;
              padding-left: 22px;
              margin-bottom: 5px;

              h4 {
                padding-bottom: 0px;
                color: $MainColor2;
              }
            }
          }
          .InfoList2 li {
            background-image: url(../assets/images/Icon_Dash.png);
          }
        }
      }
    }
  }

  .Continue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 30px;
    width: 70%;
    text-align: left;

    .FormLabel {
      font-size: 1.3rem;
      margin-bottom: 5px;
    }

    button {
      margin-top: 20px;
    }

    p {
      font-size: 1.3rem;
    }
  }

  .QRImage {
    height: 400px;
    width: 400px;
    margin-bottom: 20px;
  }

  .RegisterQR {
    height: 200px;
    width: 200px;
  }

  .RememberForgot.PromoInput {
    display: flex;
    justify-content: center;
  }
}

.Summary {
  width: 440px;

  .FlexContainer {
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .FlexContainerNoMargin {
    display: flex;
    justify-content: space-between;
  }

  .SubscriptionSummary {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    padding-bottom: 20px;
  }

  p {
    margin: 0px;
    line-height: 1.8em;
    color: $MainColor3;
  }
}

.Success {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    padding-bottom: 20px;
  }

  p {
    line-height: 1.8em;
    margin: 0px;

    &.SubText {
      font-size: 1.1rem;
    }
  }
}

.SubscriptionOption .CMSContent {
  height: 100%;
  > div:first-child {
    height: 100%;
  }
}

.Register
  .RegistrationOptions
  .SubscriptionOption
  .Holder
  .Middle
  .AddOnsDescription
  .InfoList2
  li
  h4,
.Register
  .RegistrationOptions
  .SubscriptionOption
  .Holder
  .Middle
  .AddOnsDescription
  .InfoList
  li
  h4 {
  font-weight: normal;
}
