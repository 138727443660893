.centered {
    margin: auto;
    width: 380px;
    min-width: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
}
