.InputContainer {
  position: relative;
  overflow: hidden;
  text-align: left;

  .InputContainerLabels {
    font-size: 1.3em;
    line-height: 1em;
    margin-bottom: 8px;
    color: $BasicColor2;
    text-align: left;

    .InputLabelLogos {
      height: 40px;
      width: 40px;
      position: absolute;
      bottom: 2px;
      left: 5px;
    }
  }

  .ChunkyInput {
    font-size: 1.3rem;
    line-height: 1em;
    width: 100%;
    background-color: $BasicColor7;
    border: 1px solid $BasicColor6;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 11.5px 15px;
    font-family: $Font1;
    color: $MainColor2;
    -webkit-appearance: none;
  }

  .ChunkyInputNoIcon {
    padding-left: 10px;
  }
}

.PersonalInfoForm,
.ChangePasswordForm {
  width: 440px;

  h3 {
    text-transform: uppercase;
  }

  .MultiInput {
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0px;
    }
  }
}
.PersonalInfoForm {
  input.Disabled,
  .FormLabel.Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .FormBox .ErrorMessage {
    margin-left: 0;
  }
}

.Optional {
  color: $MainColor3;
  font-style: italic;
  font-size: 1.1rem;
  margin-left: 0px;
}

.FormHint {
  color: $MainColor3;
  font-size: 1.1rem;
  margin-top: 10px;
}

.InputContainerOverflow {
  overflow: visible;

  .react-datepicker-wrapper {
    display: block;
    margin-right: 20px;
    width: 75%;
  }
}

.PaymentCardForm {
  width: 440px;
  .Disabled {
    label {
      color: #cfd7df;
    }

    input,
    html,
    .FormField,
    #card_details {
      cursor: not-allowed;
      color: #cfd7df;
    }
  }

  .ExpiryDate {
    width: 210px;
  }

  .CVV {
    width: 100px;
  }
}

.PaymentCardAddForm {
  .ExpiryDate {
    .react-datepicker-wrapper {
      margin-right: 140px;
    }
  }

  .CVV {
    .EditBox {
      width: 100px;
    }
  }
}

.NewUserAddForm,
.UpdateUserForm,
.PaymentCardAddForm {
  padding: 30px 30px 10px 30px;
}

.SubscriptionUpgrade .PaymentCardAddForm {
  padding: 0px;
}

.BillingAddressForm,
.PersonalInfoForm {
  width: 440px;
  .MultiInput {
    margin-right: 20px;
  }
  .EvenWidth {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }
}

/* Forms */
.FormBox {
  margin-bottom: 20px;
}

.FormHidden {
  display: none;
}

.FormBox .FormLabel {
  font-size: 1.3rem;
  line-height: 1em;
  margin-bottom: 8px;
  color: $MainColor2;
  text-align: left;
}

.FormBox .FormLabel.FormLabelFilters {
  display: block;
  width: 120px;
}

.FormBox .ErrorMessage {
  margin-left: 175px;
  color: $BasicColor5;
}

.FormBox .ErrorMessageLeft,
.ErrorMessageLeft {
  color: $BasicColor5;
}
.NoteMessage {
  margin-top: 0.5rem;
  font-size: 1.1rem;
}

.FormBox .ErrorMessageCenter {
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.2rem;
}

.FormField .EditBox,
.FormField .PhoneInputInput,
.FormField .react-tel-input .form-control {
  font-size: 1.3rem;
  line-height: 1em;
  width: 100%;
  background-color: $BasicColor7;
  border: 1px solid $BasicColor6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 11.5px 15px;
  font-family: $Font1;
  color: $MainColor2;
  -webkit-appearance: none;
}

.FormField .react-tel-input .form-control {
  margin-left: 38px;
  width: calc(100% - 38px);
}

.FormField .DropDown {
  font-size: 1.3rem;
  line-height: 1em;
  width: 100%;
  min-width: 175px;
  background-color: $BasicColor7;
  border: 1px solid $BasicColor6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 11.5px 15px;
  font-family: $Font1;
  color: $MainColor2;
  -webkit-appearance: none;

  &.Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.FormField .TextArea {
  font-size: 1.3rem;
  line-height: 1.3em;
  width: 100%;
  background-color: $BasicColor7;
  border: 1px solid $BasicColor6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 15px;
  font-family: $Font1;
  color: $MainColor2;
  -webkit-appearance: none;
}

.FormSideBySide .FormField.FormFieldFilters .react-datepicker-wrapper {
  margin-right: auto;
  width: 100%;
}

.FormSideBySide .FormField.FormFieldFilters .FilterPaymentCalendar .FormField {
  display: block;
}

.FormSideBySide .FormField.DatePickerOne {
  .react-datepicker-wrapper {
    width: 150px;
    margin-right: 20px;

    .react-datepicker__input-container {
      display: block;
    }
  }
}

.FormSideBySide .FormField.DatePickerTwo {
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {
      display: block;
    }
  }
}
.Required {
  color: $BasicColor5;
}
.FormFocus .FormLabel {
  color: $MainColor1;
}
.FormFocus .FormField .EditBox {
  border: 1px solid $MainColor1;
}
.FormFocus .FormField .DropDown {
  border: 1px solid $MainColor1;
}
.FormFocus .FormField .TextArea {
  border: 1px solid $MainColor1;
}
.FormLabel.Error label {
  color: $BasicColor9 !important;
}
.FormField.Error .EditBox {
  border: 1px solid $BasicColor5;
  background-color: $BasicColor9 !important;
}
.FormError .FormField .EditBox {
  border: 1px solid $BasicColor5;
  background-color: $BasicColor9 !important;
}
.FormError .FormField .DropDown {
  border: 1px solid $BasicColor5;
  background-color: $BasicColor9 !important;
}
.FormError .FormField .TextArea {
  border: 1px solid $BasicColor5;
  background-color: $BasicColor9 !important;
}

.FormRow {
  display: flex;
}

.FormBox.FormBoxWithIcon .FormField {
  position: relative;
}
.FormBox.FormBoxWithIcon .FormField .EditBox {
  padding-left: 40px;
}
.FormBox.FormBoxWithIcon .FormField img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 40px;
}

.RememberForgot {
  margin-bottom: 20px;
}
.RememberForgot .Checkbox {
  margin: 0px;
}
.RememberForgot .Link {
  text-align: right;
  display: block;
}

.FormBottom {
  padding-top: 10px;
}

.FormSideBySide {
  display: table;
  width: 100%;
}
.FormSideBySide .FormLabel {
  display: table-cell;
  width: 175px;
  padding: 0px 5px 0px 0px;
  vertical-align: middle;
}
.FormSideBySide .FormField {
  display: table-cell;
}

.FormSideBySide .FieldView {
  display: table-cell;
  vertical-align: top;
}

.FormInner {
  padding-bottom: 20px;
}

.InputContainerRow {
  position: relative;
  overflow: visible;
  text-align: left;
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    margin-left: auto;
    margin-right: 150px;

    .RowInput {
      width: 150px;
    }
  }

  .InputContainerLabels {
    font-size: 1.3rem;
  }

  .FormCheckbox {
    width: 300px;
    //margin-left: auto;
  }

  .RowInput {
    margin-top: 10px;
    padding: 0 10px;
    background-color: #f9f9fb;
    border: 1px solid #e1e5e8;
    color: #373737;
    height: 44px;
    font-size: 1.3rem;
    box-sizing: border-box;
    border-radius: 4px;
    margin-left: auto;
    width: 300px;
  }
}

.react-datepicker {
  font-size: 1.3rem !important;
  font-family: $Font1;
}
.react-datepicker-year-header {
  font-size: 1.3rem !important;
  font-family: $Font1;
}
