.OptionsList {
  position: absolute;
  top: 50px;
  z-index: 1;
  min-width: 150px;
  padding: 10px 0px;
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  background-color: $BasicColor1;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  box-shadow: 0px 0px 4px -2px $MainColor2;

  &.entering {
    display: flex;
  }

  &.entered {
    display: flex;
    opacity: 1;
  }

  &.exiting {
    display: flex;
    opacity: 0;
  }

  &.exited {
    display: none;
  }

  .OptionItem {
    padding: 10px 20px;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: $MainColor7;
    }
  }
}


/* Data Table */
.DataTableContainer {
  transform: translateZ(0);
  padding: 0 50px;
}

.DataTable {
  font-size: 1.2rem;
  line-height: 1em;
  color: $BasicColor2;
  overflow-x: auto;
  flex: 1 1 auto;
  transition: 0.3s ease-in-out;
}

.DataTable .Head {
  //position: sticky;
  top: 0px;
  background: $BasicColor1;
  z-index: 45;
}

.DataTable .Head .Cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  flex: 1 1;
  min-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 30px;
  letter-spacing: 2px;
  border-bottom: 1px solid $BasicColor7;

  &.DoubleWidth {
    flex: 2 1;
  }

  span {
    color: $BasicColor8;
    font-weight: 300;
  }

  .Checkbox {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;
  }

  .CustomInput {
    font-size: 1.3rem;
    font-weight: 300;
    letter-spacing: 1px;
    color: $MainColor2;
    padding-left: 40px;

    &:before {
    }
  }

  &:last-child {
    padding-right: 0;
  }

  &.IconOnly {
    flex: 0 1;
    min-width: 44px;

    .Icon {
      width: 44px;
      max-height: 44px;
    }
  }

  &.AlignRight {
    justify-content: flex-end;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Icon {
    width: 10px;
    height: 15px;
    cursor: pointer;
  }

  &.Sortable {
    cursor: pointer;
  }
}

.DataTable .Head.Row .Cell button {
  font-size: 1rem;
  line-height: 1em;
  color: $MainColor3;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DataTable .Head .CellFilters {
  position: absolute;
}

.DataTable .Content {
  margin-left: 0px;
}

.DataTable .Row {
  display: flex;
  min-height: 60px;
  justify-content: space-between;
  flex: 1 1;
  font-size: 1.3rem;
  min-height: 60px;
  //position: relative;
  &.Highlighted {
    background-color: $MainColor7;

    .Cell {
      background-color: $MainColor7;
    }
    
  }
}

.DataTable .Row .Cell {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 0 190px;
  border-bottom: 1px solid $BasicColor7;
}

.DataTable .Row .Cell.CellWithCheckbox {
  position: absolute;
  background-color: #fff;
  width: 50px;
  height: 60px;
  left: 0;
  border-bottom: 1px solid $BasicColor7;
  box-sizing: border-box;

  &.Highlighted {
    background-color: $MainColor7;
  }
}

.DataTable .CellFilters {
  position: absolute;
  right: 0px;
  background-color: #fff;
  width: 50px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $BasicColor7;
  box-sizing: border-box;
  overflow: visible !important;
}

.DataTable .Row .Cell.CellFilters .Tag {
  padding-right: 0;
  overflow: visible;
  position: relative;
}

.DataTable .Row.Highlighted {
  background: $MainColor7;
}

.DataTable .Row .Cell .Tag {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
  font-size: 1.2rem;
}

.DataTable .CellWithCheckbox, .DataTable .CellId, .DataTable .CellFilters {
  flex: 0 0 50px !important;
}

.DataTable .Row .Cell .Tag .FilterIcon, .DataTable .Head .Row .Cell .FilterIcon {
  height: 50px;
  width: 50px;
  cursor: pointer;

  &.Hidden {
    display: none;
  }
}



.DataTable .Head .Cell .FilterIconHead {
  width: 60px;
  height: 60px;
  cursor: pointer;

  &.Hidden {
    display: none;
  }
}

.NoResults, .NoResultsList {
  margin: 0 auto;
  text-align: center;

  img {
    height: 60px;
    width: 60px;
  }
}

.NoResultsList {
  margin-top: 25px;
}