.QRContainer {
  display: flex;
  max-width: 360px;
  min-height: 150px;
  margin: 0px auto;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  .QRImage,
  h4 {
    height: 250px;
    width: 250px;
  }

  .QRSpin {
    position: relative;
    top: auto;
    left: auto;
  }
}

.QRScanner {
  margin-bottom: 5px;
}

.QRScannerMarginTop {
  margin-top: 10px;
}

.QRScannerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-width: 250px;
  min-height: 150px;
}

.ActivateQRCode {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
  }
}

.ActivateQRContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.QRCodeDetails {
  display: flex;
  justify-content: center;
  width: 400px;

  .QRCodeDetailsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .Header {
      display: flex;
      align-items: center;

      h1 {
        padding: 0px;
      }

      .BackButton {
        position: absolute;
        top: 5px;
        left: -20px;
        width: 40px;
        border: 1px solid $BasicColor6;
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        display: block;

        img {
          width: 100%;
          display: block;
        }
      }
    }

    .ActionButtons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: -20px;

      .Btn {
        flex: 1 1 50%;
        font-weight: 400;
        margin-right: 20px;
      }
    }

    .CopyLink {
      background-color: $BasicColor7;
      height: 40px;
      width: 100%;
      border: 1px solid $BasicColor6;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      margin-top: 20px;
      font-size: 1.3rem;
      max-width: 430px;

      .Btn {
        font-size: 1.1rem;
      }

      .LinkText {
        overflow: hidden;
        //max-width: 75%;
        max-height: 25px;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .ActivateQRContainer {
    flex-direction: column;
    justify-content: space-between;
    .ActivateQRCode {
      padding-bottom: 40px;
    }
  }
}
