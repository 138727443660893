.SignInPageContainer {
  display: flex;
  flex-direction: row !important;
  height: 100%;
  overflow-x: hidden;
  margin-left: 380px;


  .SignInFormSection {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    flex: 1 2 auto;
    /*overflow-y: scroll;*/
    .SignInBox {
      width: 100%;
      //flex-basis: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .LoginBox {
        color: $BasicColor2;
        border-radius: 4px;
        padding: 40px;
        box-sizing: border-box;
        text-align: center;
        flex-basis: 400px;

        .LoginBoxTitle {
          color: $MainColor1;
          font-weight: 100;
        }

        .RememberForgot {
          display: flex;
          justify-content: space-between;
        }

        .RegisterTagline {
          font-size: 1.3rem;
        }
      }
    }

    &.QR {
      justify-content: center;
    }
  }
}

.SignInPageAside {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  width: 380px;
  background-image: url(../assets/images/Img_Aside.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-shrink: 0;
  position: fixed;

  .SignInPageLogo {
    width: 50%;
  }

  .SignInAsideTagline {
    width: 100%;
    text-align: center;
    line-height: 1em;

    .SignInAsideTitle {
      line-height: 1.6em;
      color: $BasicColor1;
      font-size: 3.5rem;
      font-weight: 100;
      margin: 0px;
      padding-bottom: 30px;
      text-align: center;
    }
  }

  .SignInAsideButtons {
    display: flex;
    width: 80%;
    justify-content: space-between;

    .Btn {
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
}