.ThirdPartyIntegrations {
  width: 75%;
  height: 100%;
}

.IntegrationContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  .IntegrationItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img {
      height: 100px;
      padding-right: 20px;
    }
    .IntegrationItemContent {
      display: block;
      padding-right: 20px;
      h2 {
        padding-bottom: 10px;
      }
      p {
        margin: 0;
      }
    }
  }
  .Btn {
    width: 120px;
  }
}

.IntegrationLoading {
  width: 100%;
  height: 100%;
  .CenterContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
