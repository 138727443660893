.SubscriptionWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .SubscriptionNoToggle {
    .CMSContent {
      min-height: auto;
      .Holder {
        min-height: 595px;
      }
    }

    .Holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .Middle {
        margin-top: 20px;

        .PriceMonth {
          display: flex;
          flex-direction: column;

          &.PriceWrap {
            flex-wrap: wrap;
          }
        }
      }

      .Continue {
        width: auto;
        margin: 0;
        margin-top: 35px;
      }
    }
  }
}
