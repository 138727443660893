.ViewPanoramas {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  h1 {
    text-align: center;
  }
}

.PanoramaItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
}
