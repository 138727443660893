.Toast {
  position: fixed;
  z-index: 300;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  transition: top 300ms ease 300ms;
  min-width: 340px;
  background: $MainColor2;
  padding: 15px;
  border-radius: 3px;

  &.Show {
    display: block;
    top: 20px;
  }

  .ToastContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      height: 15px;
      width: 15px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .ToastMessage {
    font-size: 1.4rem;
    line-height: 1.5em;
    color: $BasicColor1;
    border-radius: 3px;
    text-align: center;
  }
}
