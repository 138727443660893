.twoFactorForm {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttons button {
  margin: 0.4em 0px;
}
