.TextEditor__Outer {
  border: 1px solid #d5d5d5;
  font-family: "TTFirs-Medium";
  color: #124a57;
}

.TextEditor__Inner {
  padding: 10px;
  min-height: 350px;
}

.TextEditor__Inner:focus {
  background-color: #e3effb;
  border: 1px solid #388dcf;
}

.TextEditor__Inner h1,
.TextEditor__Inner h2,
.TextEditor__Inner h3,
.TextEditor__Inner h4,
.TextEditor__Inner p {
  margin: 0;
  padding: 0;
}

.TextEditor__Toolbar {
  border-bottom: 2px solid #d5d5d5;
  margin-bottom: 0;
}

.rdw-dropdown-carettoopen {
  border-top: 6px solid #a8a8a8;
}

.HighlightedValidation .TextEditor__Outer {
  border: 1px solid #c77979;
}

.HighlightedValidation .TextEditor__Inner {
  background-color: #fff7f7;
}

.Notes.TextEditor .TextEditor__Outer {
  display: flex;
  flex-direction: column;
}

.Notes.TextEditor .TextEditor__Inner {
  order: 1;
}

.Notes.TextEditor .TextEditor__Toolbar {
  order: 2;
}
