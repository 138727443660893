.UploadFields {
  min-width: 40rem;

  .Btn {
    width: 100%;

    .sk-spinner {
      position: relative;
      top: initial;
      left: initial;
      margin: 0;
      height: 13px;
    }
  }

  .Btn.Reset {
    background-color: transparent;
    color: $MainColor3;
    border-color: $MainColor3;
    margin-top: 0.5rem;
  }

  .NoFarms {
    font-weight: bold;
  }
}

@media all and (max-width: 425px) {
  .UploadFields {
    min-width: 30rem;
  }
}
