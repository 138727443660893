/* Width/Height */

@media only screen and (max-width: 1200px) {
  .WidthContent {
    padding-left: 50px;
    padding-right: 50px;
  }
  .HeightContent {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 1024px) {
  .WidthContent {
    padding-left: 40px;
    padding-right: 40px;
  }
  .HeightContent {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .Register,
  .PaddingContainer {
    padding: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .WidthContent {
    padding-left: 30px;
    padding-right: 30px;
  }
  .HeightContent {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .Register,
  .PaddingContainer {
    padding: 30px;
  }
}

@media only screen and (max-width: 480px) {
  .WidthContent {
    padding-left: 20px;
    padding-right: 20px;
  }
  .HeightContent {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .Register,
  .PaddingContainer {
    padding: 20px;
    width: 100%;
  }

  .Register {
    .Btn.BtnRegister {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 360px) {
  .WidthContent {
    padding-left: 10px;
    padding-right: 10px;
  }
  .HeightContent {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  h1 {
    text-align: left;
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .RouterComponent {
    height: auto;

    & > div:first-of-type {
      height: auto;
    }

    .Content {
      min-height: 100vh;
    }

    .PageWrapper {
      align-items: flex-start;
    }
  }
}

/* Footer */

@media only screen and (max-width: 1200px) {
  .Footer {
    height: auto;
    .FooterContent {
      flex-direction: column;
      justify-content: center;
      margin: 0px 50px;
      height: auto;

      .FooterContentTop {
        margin-bottom: 5px;
        flex-direction: column;
        margin-top: 10px;

        p,
        a {
          margin: 10px 0px;
        }
      }

      .Copyright {
        margin: 0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;

        .TextSeparator {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .LoggedIn.FooterSideNav {
    //width: 100%;
    width: 100vw;
  }

  .Footer.LoggedOut {
    //width: 100%;
    width: 100vw;
  }
}

@media only screen and (max-width: 480px) {
  h1 {
    font-size: 3rem;
  }
}

/* Pre-login pages */

@media only screen and (max-width: 1200px) {
  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .SubscriptionOption {
    margin-right: 0px;
    width: 100%;
    min-height: unset;
    margin-bottom: 20px;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .SubscriptionOption {
    margin-right: 0px;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .Holder {
    width: 264px;
  }
  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .Holder
    .Bottom {
    padding: 0px 20px 30px 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .SubscriptionOption
    .Holder
    .Middle
    .PriceMonth {
    flex-direction: row;
    justify-content: center;

    .Price {
      font-size: 2rem;
      line-height: 0.9em;
      font-weight: 400;
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .SignInPageContainer {
    margin-top: 60px;
    //height: auto;
  }

  .SignInPageContainer .SignInFormSection .SignInBox .Register {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  html {
    min-height: 480px; /* once aside is hidden page can be shorter */
  }

  .SignInPageAside {
    display: none !important;
  }

  .SignInPageContainer {
    margin-left: 0;
  }

  .SignInPageContainer .SignInFormSection .SignInBox {
    align-items: flex-start;
    min-height: calc(100vh - 60px);
    height: auto;
  }

  .SignInPageContainer .SignInFormSection .SignInBox .LoginBox {
    padding: 20px;
  }

  .SignInPageContainer .SignInFormSection .SignInBox .LoginBox .LoginBoxTitle,
  .SignInPageContainer .SignInFormSection .SignInBox .Register h1 {
    text-align: left;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions {
    flex-direction: column;
    margin-right: 0px;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    li {
    flex-direction: column;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .Holder {
    width: 100%;
    margin-right: 0px;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .RegistrationOptions
    .Holder
    .Bottom {
    padding: 0px 30px 30px 30px;
  }

  .SignInPageContainer .SignInFormSection .SignInBox .Register .Continue {
    width: 100%;
  }

  .SignInPageContainer
    .SignInFormSection
    .SignInBox
    .Register
    .Continue
    button {
    width: 100%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .RegistrationRouterWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .SignInPageContainer .SignInFormSection .SignInBox .Register .QRImage {
    height: 100%;
    width: 100%;
  }
}

/* Forms */

@media only screen and (max-width: 1024px) {
  .PersonalInfoForm,
  .ChangePasswordForm,
  .ErrorContainer {
    width: 100% !important;

    .FormRow {
      flex-direction: column;

      .MultiInput {
        margin-right: 0px;
      }
    }
  }

  .PaymentCardForm {
    width: 100% !important;

    .ExpiryDate {
      width: 100% !important;
    }
  }

  .BillingAddressForm {
    width: 100% !important;

    .FormRow {
      flex-direction: column;

      .MultiInput {
        margin-right: 0px;
      }
    }
  }

  .FormSideBySide .FormField.FormFieldFilters {
    display: block;
    width: 100%;

    .FormField {
      display: block;
    }
  }
}

/* Registration Summary */
@media only screen and (max-width: 1024px) {
  .Summary {
    width: 100%;
  }

  .Success {
    p {
      text-align: left;
    }
  }
}

/* Navbar */

@media (max-width: 1024px) {
  .NavBarContainer {
    width: 0px !important;
    //padding: 0px !important;
    //background: transparent !important;
    //display: block;

    .NavBarLogo {
      background-color: $BasicColor1;
      width: 100%;
      height: 60px;
      position: fixed;
      display: flex;
      z-index: 100;
      top: 0;
      left: 0;

      .NavBarLogoImg {
        display: none;
      }
    }

    .NavBarContentContainer {
      display: none;
    }
  }

  .NavBarContentContainerSmall {
    opacity: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    flex-direction: column;
    background: $MainColor2;
    transform: scale(0);
    overflow-y: auto;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    height: calc(100vh);

    &.entering,
    &.entered {
      opacity: 1;
      transform: scale(1);
      transition: 0.3s ease-in-out;
      margin-top: 0px;

      .LogOutMobileNav {
        .LogOutMobileNavText {
          display: flex !important;
          align-items: center;
          color: #a1a1a1;
          text-decoration: none;
          font-size: 1.6rem;
          justify-content: center;
          //padding: 12.5px 30px;
          cursor: pointer;
        }
      }
    }

    &.exiting {
      opacity: 0;
      transform: scale(1);
      transition: 0.3s ease-in-out;
    }

    &.exited {
      transform: scale(0);
      opacity: 0;
    }

    .NavBarMenu {
      justify-content: center;
      padding: 0px;
      flex-direction: column;
      height: calc(100vh - 80px);

      .NavBarCategoryContainer {
        display: flex !important;
        align-items: center;
        color: $BasicColor8;
        text-decoration: none;
        justify-content: center;
        padding: 12.5px 30px;
        cursor: pointer;
      }

      p {
        display: none;
      }

      .NavBarIcon {
        display: none;
      }
    }
  }

  .NavBarTrigger {
    display: block !important;
    position: fixed !important;
    right: 0px !important;
    left: unset !important;
    width: 100%;
    background-color: $BasicColor1;

    .NavLogoContainer {
      display: flex;
      align-items: center;
      color: $MainColor1;
      text-transform: uppercase;
      font-size: 1em;
      margin: 0px;
      letter-spacing: 7px;
      text-decoration: none;

      .NavSmallLogo {
        height: 60px;
        width: 60px;
        //position: fixed;
        //top: 0px;
        //left: 0px;
      }
    }

    .BtnNavMenu,
    .BtnNavCross {
      height: 60px;
      width: 60px;
    }

    .BtnNavMenu {
      position: fixed;
      top: 0;
      right: 0;
    }
  }

  .NavBarTrigger.Active {
    .BtnNavMenu {
      display: none;
    }

    /*.NavSmallLogo {
      display: none;
    }*/
  }

  .Content {
    margin-top: 60px;
    margin-left: 0px;
  }

  .BtnNav {
    display: none;
  }

  .NavBarBottom.Active {
    .BtnNavCross {
      display: block;
      height: 60px;
      width: 60px;
      display: block;
      border: 1px solid $MainColor6;
      border-radius: 50%;
      margin: auto;
      cursor: pointer;
    }
  }
  .NavBarContentContainerSmall .NavBarMenu {
    display: flex;
    justify-content: center;
    padding: 0px;
    flex-direction: column;
  }
  .NavBarContentContainerSmall .NavBarMenu .NavBarMenuItem a {
    justify-content: center;
    font-size: 1.6rem;
    color: $BasicColor8;
    text-decoration: none;
  }
  .NavBarBottom {
    padding: 0px 0px 100px 0px;
  }
}

@media (max-width: 1024px) {
  .NavBarContainer {
    &.LoggedOut {
      display: flex;
    }
  }
}

@media (max-width: 1200px) {
  .Users {
    max-width: 100%;

    .Header {
      .Btn.BtnAdd {
        width: 40px;
        padding: 0px;

        img {
          margin-right: 0px;
        }

        span {
          display: none;
        }
      }
    }

    .CardsTableContainer {
      .CardsContainer {
        .CardComponentWrapper {
          width: 50% !important;
        }
      }
    }
  }

  .UsersViewDetailsContent {
    width: 100%;

    .Header {
      h1 {
        padding-left: 30px !important;
      }
    }

    .ActionButtons {
      .Btn {
        padding: 15px 5px;
        font-size: 1.4rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .UsersViewDetails,
  .Users {
    width: 100% !important;
  }

  .Header {
    .BackButton {
      left: 0 !important;
    }
  }
}

@media (max-width: 480px) {
  .UsersViewDetails,
  .Users {
    width: 100vw !important;
    h1 {
      font-size: 3rem;
    }
  }

  .Users .CardComponent .Btn {
    padding: 12.5px;
  }

  .Header {
    .BackButton {
      left: 0 !important;
    }
  }
}

@media (max-width: 1024px) {
  .SubscriptionWrapper .Register .RegistrationOptions {
    flex-direction: column;
  }
  .SubscriptionWrapper .Register .RegistrationOptions li {
    margin-bottom: 20px;
    width: unset;
    min-height: unset;
    margin-right: 0px;
  }

  /*.SubscriptionWrapper .Register .RegistrationOptions li:last-of-type {
    margin-right: 20px;
  }*/
  .SubscriptionWrapper .Register .RegistrationOptions li .Holder {
    width: 100%;
    padding-top: 30px;
  }

  .SubscriptionWrapper .Register .RegistrationOptions li .Holder .Top {
    display: none;
  }

  .SubscriptionWrapper
    .Register
    .RegistrationOptions
    li
    .Holder
    .Middle
    .PriceMonth {
    flex-direction: row;
    justify-content: center;

    .Price {
      font-size: 2rem;
      line-height: 0.9em;
      font-weight: 400;
      margin-right: 5px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .PaymentCards {
    width: 100vw;
    h1 {
      font-size: 3rem;
    }

    .PaymentCardsBody {
      .PaymentCardsTable {
        //display: none;
        .Table {
          overflow-x: scroll;

          .Cell {
            min-width: 175px;

            .CustomInput:before,
            .CustomInput:after {
              content: unset;
            }

            &.IconOnly {
              min-width: auto;
            }
          }
        }
      }

      /*.MobileTable {
        display: block;
      }*/
    }

    .Btn.BtnClear {
      margin-right: 15px !important;
    }

    .Btn.BtnAdd,
    .Btn.BtnClear {
      width: 40px;
      padding: 0px;

      span {
        display: none;
      }
    }
  }

  .Btn.BtnAdd .AddIcon {
    margin-right: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .TransactionHistory {
    //width: 100vw;

    .TransactionHistoryBody {
      /*.TransactionHistoryTable {
        display: none;
      }

      .MobileTable {
        display: block;
      }*/
      .Table {
        overflow-x: scroll;

        .Cell {
          min-width: 250px;

          .CustomInput:before,
          .CustomInput:after {
            content: unset;
          }

          &.IconOnly {
            min-width: auto;
          }
        }
      }
    }

    .Btn.BtnEmail,
    .Btn.BtnClear {
      //width: 40px;
      padding: 0px;

      .EmailIcon {
        margin-right: 0px !important;
      }

      span {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .RightMenu {
    position: fixed;
    display: none;
    bottom: 0;
    z-index: 101;
    border-top: 1px solid #e1e1e1;
    width: 100vw;
    overflow-y: auto;
    height: 0;
    border-left: none !important;
    right: 0;

    &.entering {
      display: flex;
    }

    &.entered {
      display: flex;
      height: auto;
    }

    &.exiting {
      display: flex;
      height: 0;
    }

    &.exited {
      display: none;
      visibility: hidden;
    }

    .ItemsContainer {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .TableNavigation {
    justify-content: center;

    .PaginatorButtons {
      width: 100%;

      .Btn {
        padding: 12.5px;
        font-size: 1.1rem;
      }
    }

    .PaginatorInfo,
    .PaginatorBlank {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Notifications {
    .SettingsHeaders {
      p {
        margin-left: 20px;
      }
    }

    ul {
      li {
        .NotificationSettingsContainer {
          .Push,
          .Email {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .NotificationsSpin {
    transform: translate(0px, -60px);
  }
}

@media only screen and (max-width: 768px) {
  .Notifications {
    .SettingsHeaders {
      p {
        margin-left: 10px;
      }
    }

    ul {
      li {
        .NotificationSettingsContainer {
          .Push,
          .Email {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Notifications {
    .SettingsHeaders {
      p {
        margin-left: 5px;
      }
    }

    ul {
      li {
        .NotificationSettingsContainer {
          .Push,
          .Email {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .RightPopOut {
    width: 80%;

    .Inner {
      width: 100%;

      .react-datepicker-wrapper {
        margin-right: 100px;

        .RowInput {
          width: 100px;
        }
      }

      .RowInput,
      .FormCheckbox,
      .DisplayValue {
        width: 200px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .RightPopOut {
    width: 70%;

    .Inner {
      width: 100%;

      .react-datepicker-wrapper {
        margin-right: 75px;

        .RowInput {
          width: 75px;
        }
      }

      .RowInput,
      .FormCheckbox,
      .DisplayValue {
        width: 150px;

        .SideBySide {
          padding-right: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .ModalUnderlay {
    .Modal {
      width: 95%;

      .Inner {
        width: 100%;
      }
    }
  }
}

/* Form */
@media only screen and (max-width: 1024px) {
  .FormSideBySide {
    display: flex;
    flex-direction: column;
  }
  .PaymentCardAddForm .InputContainerRow .FormLabel {
    min-width: 175px;
  }
  .UsersAdd .InputContainerRow .FormCheckbox .Checkbox {
    padding-right: 25px;
  }
  .UsersUpdateDetails .InputContainerRow .FormCheckbox .Checkbox {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 768px) {
  .ErrorMessageResponsive {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .FormSideBySide {
    display: flex;
    flex-direction: column;
  }
  .FormSideBySide .InputContainerRow {
    flex-flow: row wrap;
  }
  .FormSideBySide .FormLabel {
    display: block;
    width: 100%;
    padding: 0px;
  }
  .FormSideBySide .FormField {
    display: block;
  }
  .FormSideBySide .FieldView {
    display: block;
  }

  .FormBox .ErrorMessage {
    margin-left: 0px;
  }

  .UsersAdd .InputContainerRow,
  .UsersUpdateDetails .InputContainerRow {
    display: block;
  }
  .UsersAdd .InputContainerRow .InputContainerLabels,
  .UsersUpdateDetails .InputContainerRow .InputContainerLabels {
    display: block;
    width: 100%;
    padding: 0px;
    margin-bottom: 10px;
  }
  .UsersAdd .InputContainerRow .FormCheckbox,
  .UsersUpdateDetails .InputContainerRow .FormCheckbox {
    width: 100%;
    margin: 0px;
  }
}

@media only screen and (max-width: 480px) {
  .Toast {
    width: 92%;
  }
}

@media only screen and (max-width: 320px) {
  .Toast {
    min-width: 300px;
  }
}

@media only screen and (max-width: 1024px) {
  .PaymentCardFilter,
  .TransactionHistoryFilter {
    .FormSideBySide {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px !important;

      .CustomSelect {
        margin-right: 0 !important;
        width: 100% !important;
        margin-bottom: 10px;
      }

      .FormField.DatePickerOne {
        margin-bottom: 25px;
        .react-datepicker-wrapper {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .SubscriptionUpgrade {
    .HeaderDetails {
      flex-direction: column;
      margin-bottom: 20px;

      h3 {
        margin-bottom: 5px;
      }
    }

    .CustomInput:after,
    .CustomInput:before {
      top: 5px;
    }

    .CustomInput {
      flex-flow: row wrap;
      height: auto;

      &.ShowCVV {
        &:after {
          top: 30px;
        }
      }

      .CVV {
        margin-left: 0 !important;
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .SelectColumn,
  .FilterColumn {
    display: flex;
    flex-direction: column;
    width: 60px;
    position: absolute;
    top: 20px;
    left: -18px;
    z-index: 100;
    padding: 0px 20px;
    box-shadow: 19px 0 27px -17px rgba(0, 0, 0, 0.1);
    background: white;
  }

  .SelectColumn {
    padding: 0px;
    top: 0px;

    .MobileCheckbox {
      margin: 0px;
      padding: 20px;
      padding-bottom: 19px;
      border-bottom: 1px solid $BasicColor7;

      &.Highlighted {
        background-color: $MainColor7;
      }
    }
  }

  .FilterColumn {
    left: auto;
    top: 0px;
    right: -18px;
    padding: 0px;
    box-shadow: -17px 0 27px -17px rgba(0, 0, 0, 0.1);
  }
  .IconSmallScreenHide {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .ErrorResults {
    height: calc(100vh - 60px);
  }
}

@media only screen and (max-width: 1024px) {
  .ErrorPageContainer {
    height: calc(100vh - 60px);
  }
}

@media only screen and (max-width: 1200px) {
  .CMSContent {
    min-height: 100vh;
  }
}

@media only screen and (max-width: 1024px) {
  .RegistrationSection {
    width: 90%;

    .RegisterSection {
      width: 100%;
      flex-direction: column;

      .RegistrationSummary {
        margin: 0;
        width: 100%;
        padding: 0px;

        .Summary {
          margin: 30px 0 40px 0;
          max-width: 100%;
        }
      }
      .RegistrationWrapper {
        margin: 0px;
        min-width: auto;
        width: 100%;
        padding: 0px;
        max-width: 100%;

        .RegistrationStep {
          max-width: 100%;
          margin: 10px 0;
          padding: 20px;

          .MultiInput {
            width: 100%;
          }

          .Register {
            .RegistrationOptions {
              .SubscriptionOption {
                .Holder {
                  padding: 20px;

                  .Middle {
                    margin-left: 20px;

                    .PriceMonth {
                      justify-content: flex-start;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .RegisterSection {
    .RegistrationWrapper {
      width: 60%;
    }

    .RegistrationSummary {
      width: 40%;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .RegisterSection {
    flex-direction: column;
    .RegistrationWrapper {
      width: 100%;
      padding: 50px;
      padding-bottom: 0;
    }
    .RegistrationSummary {
      width: 100%;
      padding: 50px;
      padding-top: 0;
      .Summary {
        max-width: 100%;
      }
      .CMSTextField {
        max-width: 100%;
      }
    }
  }
}
