.Users {
  max-width: calc(100vw - 240px);
  min-height: calc(100vh - 60px);
  height: 100%;
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h1 {
      text-align: left;
      padding-bottom: 0px;
      margin-bottom: 40px;
    }
  }

  .CardsTableContainer {
    .CardsContainer {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      width: calc(100% + 20px);

      .CardComponentWrapper {
        display: block;
        vertical-align: top;
        box-sizing: border-box;
        margin: 0px;
        width: 20%;
        padding: 0 20px 20px 0;

        .CardComponent {
          padding: 20px;
          border: 1px solid $BasicColor7;
          background: $BasicColor1;
          box-sizing: border-box;
          border-radius: 3px;
          width: 100%;
          height: 100%;
          cursor: pointer;

          .UserInitials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 80px;
            width: 80px;
            background-color: $MainColor1;
            color: $BasicColor1;
            font-size: 3.5rem;
            font-weight: 100;
            text-align: center;
            border-radius: 50%;
            margin: 20px auto 30px auto;
          }

          .TextDetails {
            text-align: center;
            margin-bottom: 20px;
            display: block;
            width: 100%;

            .UserName {
              font-size: 1.3rem;
              line-height: 1.3em;
            }

            .Company {
              font-size: 1.3rem;
              color: $MainColor3;
            }
          }
        }
      }
    }

    .CardNavigation {
      padding: 20px 0 0;
      height: 60px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .PaginatorBlank {
        width: 25%;
      }

      .PaginatorButtons {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
      }

      .PaginatorInfo {
        font-size: 1.2rem;
        width: 25%;
        text-align: right;
      }
    }
  }
}

.UsersViewDetails {
  display: flex;
  justify-content: center;
  width: 100%;

  .UsersViewDetailsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 30px 30px 10px 30px;
    min-height: calc(100vh - 120px);
    width: 100%;

    .Header {
      display: flex;
      align-items: center;

      h1 {
        padding: 0px;
      }

      .BackButton {
        position: absolute;
        top: 5px;
        left: -20px;
        width: 40px;
        border: 1px solid $BasicColor6;
        border-radius: 50%;
        cursor: pointer;
        box-sizing: border-box;
        display: block;

        img {
          width: 100%;
          display: block;
        }
      }
    }

    .ActionButtons {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: -20px;

      .Btn {
        flex: 1 1 50%;
        font-weight: 400;
        margin-right: 20px;
      }
    }

    .CopyLink {
      background-color: $BasicColor7;
      height: 40px;
      width: 100%;
      border: 1px solid $BasicColor6;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;
      margin-top: 20px;
      font-size: 1.3rem;
      margin-bottom: 20px;
      // max-width: 430px;

      .Btn {
        font-size: 1.1rem;
      }

      .LinkText {
        overflow: hidden;
        //max-width: 75%;
        max-height: 25px;
        margin: 0;
      }
    }

    .UserDetailsContainer,
    .PermissionDetailsContainer {
      width: 100%;
      // margin-top: 20px;

      .UserDetailsHeader,
      .PermissionDetailsHeader {
        display: flex;
        justify-content: space-between;

        h3 {
          padding: 0px;
        }

        .Btn {
          border-radius: 50%;
          height: 40px;
          width: 40px;
          padding: 0;
          background-color: $MainColor4;

          img {
            height: 40px;
            width: 40px;
          }
        }
      }

      .UserDetailsContent {
        p {
          margin: 0px;
          line-height: 1.9em;
        }
      }
    }
  }
}

.Users
  .CardsTableContainer
  .CardsContainer
  .CardComponentWrapper
  .CardComponent:hover {
  background: $MainColor7;
}

.UsersAdd,
.UsersUpdateDetails {
  .InputContainerLabels {
    min-width: 175px;
    padding: 15px 0px;
  }

  .FormCheckBox {
    margin-left: 0px;
  }
}

.UsersAdd {
  position: relative;
  &__generalError {
    padding-left: 5px;
    position: absolute;
    bottom: 60px;
    color: $BasicColor5;
  }
  &__upgradeNotice {
    padding: 30px 30px 0px 30px;
    font-size: 12px;
    color: $MainColor3;
    margin: 0;
  }
}

.UserTypeSelect {
  width: 100%;
  padding: 30px 30px 0px 30px;

  &.Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .UserTypeSelectInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border: 1px solid $BasicColor6;
    border-radius: 3px;
    padding: 30px 20px;
    cursor: pointer;
    .UserTypeSelector {
      display: flex;
      align-items: center;
      p {
        font-size: 20px;
        color: $MainColor3;
        margin: 0;
      }
    }
    .UserTypeDescription {
      display: flex;
      margin-top: 0.5rem;
      p {
        color: $MainColor3;
      }
    }
    /* The container */
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 27px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default radio button */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: $BasicColor7;
      border-radius: 50%;
      border: 1px solid $BasicColor6;
    }

    .container input:checked ~ .checkmark {
      border-color: $MainColor1;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .container .checkmark:after {
      top: 3px;
      left: 3px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background: $MainColor1;
    }
  }
  .UserTypeSelectInner.Active {
    border-color: $MainColor1;
  }
}

.UserTypeDescription {
  p {
    margin: 0;
    font-size: 12px;
  }
}
