.TransactionHistory {
  min-height: calc(100vh - 60px);
  .TransactionHistoryHeader {
    display: flex;
    justify-content: space-between;

    h1 {
      text-align: left;
    }
  }

  .TransactionHistoryBody {
    position: relative;
  }

  .EmailStripeButtonContainer {
    margin-left: auto;
    margin-right: 20px;
  }
}
