$Font1: "Roboto", arial, sans-serif;

$BasicColor1: #ffffff; /* white */
$BasicColor2: #000000; /* black */
$BasicColor3: #504e53; /* grey */
$BasicColor4: #7cd838; /* valid green */
$BasicColor5: #d83838; /* error red */
$BasicColor6: #dedfdf; /* light grey */
$BasicColor7: #f1f1f1; /* whitesmoke */
$BasicColor8: #a1a1a1; /* dark grey */
$BasicColor9: #f19a9a; /* darksalmon */
$BasicColor10: #ffecec; /* linen */
$BasicColor11: #fcfcfc; /* snow */
$BasicColor12: #feefee; /* light error red */
$BasicColor13: #ed8686; /* light coral */

$MainColor1: #18c60b; /* limegreen */
$MainColor2: #333333; /* darkslategray */
$MainColor3: #8e8e8e; /* gray */
$MainColor4: #e7f8e6; /* honeydew */
$MainColor5: #808080; /* light gray */
$MainColor6: #787878; /* shadow gray */
$MainColor7: #fcfcfc; /* bg gray */

/* z-index and opacity fixes */
/* https://www.w3.org/TR/css-color-3/#transparency */
$SpecialColor1: rgba(142, 142, 142, 0.6); /* MainColor3 with opacity */
$SpecialColor2: rgba(241, 241, 241, 0.6); /* BasicColor7 with opacity */

.Accessible {
  $BasicColor9: #d31d1d; /* darksalmon accessible */
  $BasicColor13: #df2a2a; /* light coral accessible */

  $MainColor1: #0e7f06; /* limegreen accessible */

  /* General/Sitewide */
  @import "General";
  @import "NavBar";
  @import "Forms";
  @import "Lists";
  @import "RightPopOut";
  @import "Modal";
  @import "Toast";
  @import "QRCode";
  @import "Invoices";
  @import "FieldReports";

  /* Pages before logging in */
  @import "PreLogin";
  @import "Registration";
  /* Static pages */
  @import "TermsConditions";
  /* Other pages */
  @import "Users";
  @import "Subscription";
  @import "PaymentCards";
  @import "TransactionHistory";
  @import "Notifications";
  /* Media Queries */
  @import "MediaQueries";
}

/* General/Sitewide */
@import "General";
@import "NavBar";
@import "Forms";
@import "Lists";
@import "RightPopOut";
@import "Modal";
@import "Toast";
@import "QRCode";
@import "Dropzone";
@import "ImprovedTextEditor";

/* Pages before logging in */
@import "PreLogin";
@import "Registration";

/* Static pages */
@import "TermsConditions";

/* Other pages */
@import "Users";
@import "Subscription";
@import "PaymentCards";
@import "TransactionHistory";
@import "Notifications";
@import "Invoices";
@import "FieldReports";
@import "UploadFields";
@import "ThirdPartyIntegrations";
@import "ViewPanoramas";
@import "FieldReportsSection";
@import "ApplicationMaps";

/* Media Queries */
@import "MediaQueries";
