.PaymentCards {
  min-height: calc(100vh - 60px);
  .PaymentCardsHeader {
    display: flex;
    justify-content: space-between;

    .ButtonContainer {
      display: flex;
      margin-right: -20px;

      .Btn {
        margin-right: 20px;
      }
    }
  }
  .PaymentCardsBody {
    position: relative;
  }
}