.FieldReportsContainer {
  max-width: 1024px;
  height: fit-content;
  margin: 0 auto;
  position: relative;
  display: block;
  clear: both;
  break-before: always;
  page-break-before: always;
  page-break-inside: avoid;
  break-inside: avoid;

  &__NotesHeading {
    color: $MainColor1;
    font-size: 18px;
    font-family: "Roboto";
  }
  &__NotesContent {
    font-family: "Roboto";
  }

  &.PdfReport {
    height: 1367px;
  }
  .TopLogo {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 35px;
    border-radius: 50%;

    &.AgriiLogo {
      left: 92px;
      height: 35px;
      width: 35px;
      object-fit: cover;
    }
  }
  .PageContentBox {
    padding: 60px 60px 40px;
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;

    @media only screen and (max-width: 768px) {
      padding: 60px 30px 40px;
    }

    .PDFHeaderContainer {
      h2 {
        color: $MainColor1;
        font-weight: 300;
        font-size: 28px;
        padding-bottom: 0;
      }
      h3 {
        padding-bottom: 40px;
      }
      h3 span {
        color: $MainColor1;
      }
    }
    .DataSection {
      padding-bottom: 40px;
      h4 {
        color: $MainColor1;
        font-weight: 300;
        font-size: 18px;
        padding-bottom: 10px;
        span {
          font-size: 12px;
          color: #333333;
        }
      }
    }
    .DataSection.SmallSection {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
    }
    .DataItemContainer {
      display: flex;
      display: -webkit-flex;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;

      .NoValues {
        font-size: 24px;
        font-weight: 600;
      }
      .ItemContainer {
        padding: 1rem 0rem;
        display: flex;
        display: -webkit-flex;
        padding-left: 0;
        width: 25%;

        .ItemContent {
          padding: 0 1rem;
        }
        img {
          height: 25px;
          width: 25px;
        }
        h5 {
          font-size: 18px;
          font-weight: 400;
          margin: 0;
          span {
            color: #18c60b;
          }
        }
        p {
          margin: 0;
          font-size: 10px;
          span {
            color: #18c60b;
          }
        }
      }
    }
    .DataItemContainer.SmallSection {
      flex-direction: column;
    }
    .GlossaryTop {
      padding: 0;
      padding-top: 10px;
    }
    .GeneralGlossary {
      padding-bottom: 0;
      .GeneralItemContainer {
        display: flex;
        .GeneralItem {
          width: 50%;
          margin-bottom: 20px;
          p {
            margin-top: 0;
            margin-bottom: 10px;
          }
        }
        .GeneralItem:nth-of-type(2n-1) {
          padding-right: 20px;
        }
      }
    }
    .Glossary {
      .GlossaryTerms {
        .Individual {
          // display: flex;
          align-items: center;
          .ItemContainer {
            width: 14%;
            p {
              white-space: nowrap;
            }
          }
          .Description {
            width: 86%;
          }
        }
      }
    }
  }
  .OtherStats {
    display: flex;
    display: -webkit-flex;
  }
  .PageTop {
    padding: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .SummaryImage {
    width: 100%;
    border-radius: 10px;
    border: 1px solid $MainColor1;
    overflow: hidden;
    max-height: 760px;
    &.SmallerSummaryImage {
      width: 772px;
      max-height: 650px;
      margin: 0 auto;
      page-break-inside: avoid;
      break-inside: avoid;
    }
    img {
      max-height: 100%;
      overflow: hidden;
      width: 100%;
      break-inside: avoid;
      page-break-inside: avoid;
    }
  }
}

.ImagesContainer {
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 370px 20px 370px;
  break-inside: avoid;
  page-break-inside: avoid;

  .Image {
    border-radius: 10px;
    border: 1px solid $MainColor1;
    grid-column: 1;
    grid-row: 1/-1;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
    .ScoutPointIconWrapper {
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(-20px) translateY(-20px);
    }
    .ScoutPointIconNumber {
      position: absolute;
    }
    .ScoutPointIcon {
      width: 100%;
      height: 100%;
    }
  }
  .StackImages {
    grid-column: 3/4;
    grid-row: 1/-1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .PlaceholderImage {
      border-radius: 10px;
      max-width: 100%;
      border: 1px solid #18c60b;
      width: 100%;
      height: 100%;
      max-height: 370px;
      img {
        width: 100%;
        height: 100%;
        border: none;
        height: auto;
        max-height: 370px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px solid $MainColor1;
      max-height: 370px;
    }
  }
}

.FieldReportsFooter {
  margin: 0 auto;
  padding-top: 10px;
  text-align: center;
  padding-bottom: 0;
  span {
    color: $MainColor1;
    padding: 0 5px;
  }
}
.FooterContainerFieldReports {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 1000px;
  height: fit-content;
  p {
    font-size: 14px;
    color: $BasicColor8;
  }
}

.ReportsDebug {
  width: 100%;
  height: 100%;
}

.NotVisited {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 400px;
  img {
    width: 35px;
    margin-bottom: 10px;
  }
  h2 {
    color: #18c60b;
    font-weight: 300;
    font-size: 28px;
    padding-bottom: 0;
    margin: 0;
  }
  p {
    margin: 0;
    font-style: italic;
  }
}

// Styling for KRPano Report
.FieldReportsContainer.KrpanoReport {
  width: initial;

  .SummaryImage.SmallerSummaryImage {
    width: initial;
  }
  .PageContentBox {
    .ImagesContainer {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      flex-direction: column;
      gap: 10px;
      max-height: initial;
      .Image {
        grid-row: 1;
        max-width: initial;
        margin-right: 0 !important;
        img {
        }
      }
      .StackImages {
        grid-row: 2;
        grid-column: 1;
        width: 100%;
        max-height: initial;
        flex-direction: column;
        display: flex;
        flex: 1 1 auto;
        .PlaceholderImage {
          img {
            height: initial;
            max-height: initial;
            max-width: initial;
          }
        }

        img {
          max-height: initial;

          grid-column: 1;
        }
      }
    }
    .DataItemContainer {
      gap: 10px;
      .ItemContainer {
        width: initial;
      }
    }
    .Glossary .DataItemContainer {
      gap: 1px;
    }
  }
}
.FieldReportsContainer.Notes {
  margin: 0;
}
.FieldReportsContainer.Notes .PageTop {
  padding: 0;
}
.FieldReportsContainer.Notes .PageContentBox {
  padding-top: 40px;
}
