.InvoiceContainer {
  width: 1000px;
  min-height: 1020px;
  margin: 0 auto;
  .PageContentBox {
    padding: 40px;
    padding-top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .HeaderContainer {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 2px solid #f2f2f2;
      padding: 40px 0;
      margin-bottom: 65px;
      .LeftSide {
        h1 {
          color: $BasicColor2;
          font-size: 30px;
          font-weight: 400;
          text-align: start;
          padding-bottom: 20px;
        }
        div {
          font-size: 20px;
          padding-bottom: 3px;
          font-weight: 300;
        }
        .SubTitle {
          color: $BasicColor2;
        }
        .TimeDate {
          color: $BasicColor8;
        }
      }
      .RightSide {
        img {
          width: 300px;
        }
      }
    }
    .DetailsContainer {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 70px;
      .LeftSideContainer {
        display: flex;
      }
      p {
        font-weight: 300;
        margin: 3px 0;
        font-size: 16px;
      }
      .Bold {
        font-weight: 700;
      }
      .LeftSideRight {
        padding-left: 100px;
      }
      h2 {
        color: $BasicColor2;
        font-weight: 300;
        font-size: 23px;
        padding-bottom: 5px;
      }
      .LeftSide {
        padding-bottom: 40px;
        div {
          font-size: 16px;
          padding-bottom: 3px;
          font-weight: 300;
        }
        .SubTitle {
          color: $BasicColor2;
        }
        .TimeDate {
          color: $BasicColor8;
        }
      }
      .RightSide {
        padding-right: 50px;
        img {
          width: 130px;
          padding-top: 15px;
        }
      }
    }
    .SummaryContainer {
      width: 100%;
      .TableContainer {
        table {
          width: 100%;
          border-collapse: collapse;
          border-bottom: 2px solid #f2f2f2;
          margin-bottom: 15px;
          tr {
            font-size: 13px;
            th {
              background-color: $BasicColor7;
              text-transform: uppercase;
              padding: 12px;
              font-weight: 600;
              letter-spacing: 1px;
            }
            td {
              padding: 12px;
              color: $BasicColor8;
            }
            .Left {
              text-align: left;
              padding-left: 20px;
            }
            .Middle {
              text-align: left;
            }
            .Right {
              text-align: right;
              padding-right: 20px;
            }
          }
        }
      }
      .PriceSummary {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        padding-right: 10px;

        .SubTotal {
          padding: 5px;
          font-weight: 700;
          letter-spacing: 2px;
          font-size: 15px;
        }

        span {
          padding-left: 50px;
          font-weight: 400;
          letter-spacing: normal;
          color: $BasicColor8;
        }
      }
    }
  }
}
.FooterContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 1000px;
  border-top: 2px solid #f2f2f2;
  height: fit-content;
  p {
    font-size: 14px;
    color: $BasicColor8;
  }
}
