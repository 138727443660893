.ApplicationMaps {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
  line-height: 1.8em;

  &__description {
    text-align: center;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    div:last-of-type {
      margin-top: 10px;
      font-weight: 700;
    }
    div:first-of-type {
      margin-top: 0;
    }
  }

  .FormInner {
    max-width: 440px;
    &.Disabled {
      .FormBox:not(:first-of-type) {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
  .FormBox.Disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}
