html {
  font-size: 62.5%;
  background: $BasicColor1;
  min-height: 620px;
  height: 100%;
  overflow-y: visible;
}

body {
  color: $MainColor2;
  font-family: $Font1;
  padding: 0px;
  margin: 0px;
  min-width: 320px;
  min-height: 100%;
  display: flex;
  overflow-y: visible;

  &.RightOpen {
    overflow: hidden;
  }
}

// Need this on the pre-main router as now main children direct descendent of this
.PreRouter {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100vh;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100vh;
  //overflow-x: hidden;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  font-family: $Font1;
}

.RouterComponent {
  height: 100%;
  //height: auto;
  //width: 100%;
  width: 100vw;
  background-color: $BasicColor1;
  //overflow-x: hidden;

  &.RightOpen {
    height: 100vh;
    overflow: hidden;
  }
  /* This is because of a CSSTransition bug - sometimes .Page class below is not set on rerenders */
  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.Page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Content {
  margin-left: 240px; /* Left menu margin*/
  height: 100%;
}

.WidthContent {
  //max-width: 1080px; - used in most other projects
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
}

.HeightContent {
  padding-top: 60px;
  padding-bottom: 60px;
}

.PaddingContainer {
  padding: 40px;
}

.PageWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Commonly Used */
h1 {
  font-family: $Font1;
  font-size: 3.5rem;
  line-height: 1.3em;
  font-weight: 100;
  margin: 0px;
  padding-bottom: 30px;
  color: $MainColor1;
  text-align: center;
}

h2 {
  font-family: $Font1;
  font-size: 2rem;
  line-height: 1.3em;
  font-weight: 400;
  margin: 0px;
  padding-bottom: 20px;
  color: $MainColor3;
}

h3 {
  font-family: Roboto;
  font-size: 1.5rem;
  letter-spacing: 2px;
  line-height: 1.6em;
  font-weight: 300;
  margin: 0px;
  padding-bottom: 30px;
  color: $MainColor2;
}

h4 {
  font-family: Roboto;
  font-size: 1.3rem;
  line-height: 1.6em;
  font-weight: 700;
  margin: 0px;
  padding-bottom: 20px;
  color: $MainColor3;
}

p {
  color: $MainColor2;
  font-size: 1.3rem;
  line-height: 1.8em;
  font-weight: 400;
}

a {
  color: $MainColor1;
  font-size: 1.3rem;
  font-weight: 400;
}

ol {
  font-size: 1.3rem;
  line-height: 1.8em;
}

.NoPadding {
  padding: 0px;
}

.Error {
  color: $BasicColor5;
}

.ErrorMessageCenter {
  color: $BasicColor5;
  text-align: center;
}

.ErrorMessageResponsive {
  color: $BasicColor5;
  text-align: left;
  margin: auto;
  margin-bottom: 20px;
}

.ErrorContainer {
  font-size: 1.1rem;
  color: $BasicColor5;
  background-color: $BasicColor12;
  padding: 15px;
  border-radius: 3px;
  text-align: left;
  line-height: 1.6em;
  width: 440px;
  margin-bottom: 20px;
}

/* Buttons */

.Btn {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1em;
  font-weight: 400;
  color: $BasicColor1;
  text-align: center;
  border: 1px solid $MainColor1;
  border-radius: 30px;
  box-sizing: border-box;
  background: $MainColor1;
  padding: 12.5px 25px;
  cursor: pointer;
  text-decoration: none;
  font-family: $Font1;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.Btn:hover {
  opacity: 0.8;
}

.Btn:disabled {
  opacity: 0.6;
  cursor: auto;
}

.Btn.Disabled {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

.Btn:disabled:hover {
  opacity: 0.6;
}

.Btn.BtnSmall {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 400;
  padding: 10px 15px;
}

.Btn.BtnCopy {
  line-height: 0em;
  height: 100%;
  width: 80px;
  min-width: 80px;
}

.Btn.BtnGreen {
  background: $MainColor1;
  border: 1px solid $MainColor1;
}

.Btn.BtnWhite {
  background-color: $BasicColor1;
  color: $MainColor3;
  border: 1px solid $BasicColor7;
  font-weight: 400;
}

.Btn.BtnRed {
  background-color: $BasicColor13;
  color: $BasicColor1;
  border: 1px solid $BasicColor13;
}

.BtnGrey {
  background: $MainColor2;
  border: 1px solid $BasicColor8;
  color: $BasicColor8;
  font-weight: 400;
}

.Btn.BtnTransparent {
  background: rgba(0, 0, 0, 0);
  border: 1px solid $MainColor1;
}

.Btn.BtnFullWidth {
  width: 100%;
}

.BtnMargin {
  margin-bottom: 20px;
}

.BtnMarginTop {
  margin-top: 20px;
}

.Btn.BtnAdd {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $MainColor4;
  border: 1px solid $MainColor4;
  color: $MainColor1;
  height: 40px;
  padding: 0px;
  padding-right: 25px;
}

.Btn.BtnClear {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $BasicColor1;
  color: $MainColor3;
  border: 1px solid $BasicColor7;
  height: 40px;
  padding: 0px;
  padding-right: 25px;

  .ClearIcon {
    height: 40px;
    width: 40px;
  }
}

.Btn.BtnEmail {
  font-size: 1.1rem;
  margin: 0px;
  padding-right: 15px;
  padding-left: 5px;
  color: $MainColor2;
  height: auto;

  .EmailIcon {
    height: 30px;
    width: 30px !important;
  }
}

.Btn.BtnAdd .AddIcon {
  height: 38px;
  width: 38px;
  margin-right: 5px;
}

.Btn.BtnDelete {
  color: $BasicColor9;
  background: $BasicColor10;
  border: none;
  font-weight: 400;
}

.Btn.BtnRegister {
  width: 440px;

  &.BtnTransparent {
    color: $MainColor2;
  }
}

.Btn.BtnDisabled {
  opacity: 0.2;
  cursor: default;
}

.Btn.BtnHidden {
  display: none;
}

.PaginatorBtn {
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1em;
  text-align: center;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 12.5px 25px;
  cursor: pointer;
  text-decoration: none;
  font-family: $Font1;
  background-color: $BasicColor1;
  color: $MainColor3;
  border: 1px solid $BasicColor7;
  font-weight: 400;
}

// Opacity interferes with z-index - SpecialColor
.PaginatorBtn:hover {
  color: $SpecialColor1;
  border-color: $SpecialColor2;
}

.PaginatorBtn:disabled {
  color: $SpecialColor1;
  border-color: $SpecialColor2;
  cursor: auto;
}

/* Inputs */
.Checkbox {
  margin: 0px 0px 20px 0px;
  text-align: left;
}

.MobileCheckbox {
  /*margin: 20px;
  width: 20px;
  height: 20px;*/
  margin: 20px 0px;

  &:first-of-type {
    margin-top: 0;
  }

  .CustomInput:before,
  .CustomInput:after {
    /*margin: 20px;
    right: -10px;*/
  }
}

.Checkbox label {
  font-size: 1.3rem;
  line-height: 1.3em;
  font-weight: 400;
  color: $MainColor2;
  padding-left: 30px;
  width: auto;
}

.Checkbox.SideBySide {
  display: inline-block;
  padding-right: 30px;
  margin: 0px 0px 5px 0px;
}

input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.CustomInput {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 0 0 0 30px;
  height: 20px;
  user-select: none; /* Standard */
  font-size: 1.3em;
  line-height: 1.3em;

  &.MarginBottom {
    margin-bottom: 15px;
  }
}

.CustomInput:before,
.CustomInput:after {
  position: absolute;
  top: 1px;
  left: 0;
  display: block;
  transition: all 0.3s ease-out;
  width: 18px;
  height: 18px;
  background-image: none;
}

.CustomInput:before {
  content: " ";
  border-radius: 3px;
  background-color: $BasicColor7;
  border: 1px solid $BasicColor6;
  box-sizing: border-box;
}

input[type="checkbox"] + .CustomInput::after {
  content: "";
  color: $MainColor1;
  text-align: center;
  margin: 0px;
  background-image: none;
}

input[type="checkbox"]:disabled + .CustomInput {
  opacity: 0.3;
  cursor: default;
}

input[type="radio"] + .CustomInput:after {
  content: "";
  text-align: center;
  margin: 0;
  background-image: none;
  background-size: 30px;
  background-repeat: no-repeat;
}

input[type="radio"] + .CustomInput:before {
  border-radius: 100%;
}

input[type="checkbox"]:checked + .CustomInput:before {
  background: $MainColor1;
  border: 1px solid $MainColor1;
  background-image: url(../assets/images/Icon_Tick_White.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
}

input[type="radio"]:checked + .CustomInput:before {
  background: $MainColor1;
  border: 1px solid $MainColor1;
  background-image: url(../assets/images/Icon_Tick_White.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  box-sizing: border-box;
}

input[type="checkbox"]:checked + .CustomInput:after,
input[type="radio"]:checked + .CustomInput:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* Footer */
.Footer {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .FooterContent {
    display: flex;
    width: 100%;
    margin: 0px 60px;
    height: 60px;
    border-top: 1px solid $BasicColor6;
    align-items: center;

    a,
    p {
      font-size: 1rem;
      color: $MainColor3 !important;
    }

    .FooterButton {
      padding: 0px;
      text-decoration: none;
      color: $MainColor2;
      margin-right: 10px;
      cursor: pointer;
    }
    .Copyright {
      margin-right: 0px;
      margin-left: auto;
    }
  }
}

.Footer.LoggedOut {
  //width: calc(100% - 380px);
  width: calc(100vw - 380px);
  align-self: flex-end;
}
.Footer.LoggedIn {
  width: calc(100% - 240px);
  align-self: flex-end;
}

.FooterSideNav {
  width: calc(100% - 240px);
  align-self: flex-end;
}

.FlexContainer {
  display: flex;
}

.FlexContainerCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FlexContainerSpace {
  display: flex;
  justify-content: space-between;
}

.Uppercase {
  text-transform: uppercase;
}

.RightMenu {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $BasicColor1;
  width: 0;
  min-width: 0;
  overflow: hidden;
  visibility: hidden;
  transition: min-width 0.2s ease-in-out;
  white-space: nowrap;

  &.entered {
    min-width: 240px;
    visibility: visible;
    border-left: 1px solid $BasicColor7;
    box-sizing: border-box;
  }

  &.exiting {
    visibility: visible;
    /* Prevent flash of menu contents switching from multiple to single*/
    .ItemsContainer {
      display: none;
    }
  }

  &.exited {
    visibility: hidden;
  }

  .ItemsContainer {
    position: fixed;
    padding: 40px 0 0 0px;
    width: 100%;

    .Item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      padding: 0px 15px;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.3rem;
      line-height: 0.8em;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: $MainColor7;
      }

      .Icon {
        width: 40px;
        margin-right: 5px;
      }
    }
  }
}

.TableNavigation {
  padding: 30px 0 0;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .PaginatorBlank {
    width: 25%;
  }

  .PaginatorButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  .PaginatorInfo {
    font-size: 1.1rem;
    width: 25%;
    text-align: right;
  }
}

/* Display details components */

.DisplayRow {
  display: flex;
  font-size: 1.3rem;
  margin-bottom: 30px;

  .DisplayLabel {
  }

  .DisplayValue {
    color: $MainColor3;
    width: 300px;
    margin-left: auto;
  }
}

/* Select dropdowns */
.react-select__control {
  background-color: $BasicColor7 !important;
  border: 1px solid $BasicColor6 !important;

  .react-select__placeholder,
  .react-select__single-value {
    font-size: 1.3rem;
    color: $MainColor3;
  }

  .react-select__indicator-separator {
    width: 0px;
  }

  .react-select__indicator {
    svg {
      stroke: $MainColor3 !important;
      fill: $MainColor3 !important;
    }
  }
}

.TableNavigation .PaginatorButtons span {
  font-size: 1.3rem;
}

.Hide {
  display: none;
}

.SiteMap {
  height: 100%;
}

// Loading

.sk-spinner {
  margin: 20px auto;
  position: absolute;
  top: 50%;
  left: 50%;
}

.BtnSpinner {
  position: relative;
  top: auto;
  left: auto;
  margin: 0 auto;
}

.BtnSpinner.BtnSpinnerSmall {
  height: 13px;

  > div {
    height: 8px;
    width: 8px;
  }
}

.ErrorResults {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.TableErrorResults {
  height: calc(100vh - 255px);
}

.NoMargin {
  margin: 0 !important;
}

.Bold {
  font-weight: 700;
}

.ErrorPageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  a {
    text-decoration: none;
    color: white;
    font-size: 1.3rem;
  }
}
