.CMSContent {
  max-width: 100vw;
  width: 100%;
  min-height: calc(100vh - 60px);
  h1, h3 {
    text-align: left;
    padding-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
    margin-top: 0px;
  }

  ul {
    margin-bottom: 0px;
    margin-top: 0px;
    padding-left: 0px;
    list-style-position: inside;

    li {
      font-size: 1.3rem;
      line-height: 1.8em;
    }
  }
}
