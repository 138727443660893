.container {
  height: 100%;
  width: 30%;
  margin: auto;
  display: flex;
}

.base {
  margin: auto;
  min-width: 450px;
}

.base.show {
  opacity: 1;
}

@media (max-width: 500px) {
  .base {
    min-width: 100%;
  }
  .container {
    width: 100%;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.buttons a {
  text-decoration: none;
}

.signInForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.signInForm :global(.FormBox) {
  position: relative;
  font-family: "swissra", Arial, Helvetica, Verdana, Sans-Serif;
  margin-bottom: 10px;
}

.signInForm :global(.FormBox) label {
  margin: 0;
}

.signInForm :global(.FormBox) label:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 1px;
  width: 0;
  background: #238acb;
  transition: 0.2s ease-in-out;
}

.signInForm :global(.FormBox) input {
  height: 42px;
  padding: 0;
  width: 100%;
}

.signInForm :global(.FormBox) input:focus {
  border-bottom: 1px solid #c3baba;
}

.signInForm :global(.FormBox) input:focus + label:after {
  width: 100%;
}
