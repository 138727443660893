.forgotPasswordForm {
  display: flex;
  flex-direction: column;
}

/* .forgotPasswordForm > div { */
/*   display: flex; */
/*   justify-content: space-around; */
/* } */

.buttonsHolder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.success,
.failed {
  text-align: center;
}

.success h3 {
  padding-bottom: 20px;
}

.failed h3 {
  padding-bottom: 20px;
}

